@use '@publicismedia-ds/ui-theme' as theme;

.match-campaigns-filter-container {
    padding: 1rem 0;
    width: 100%;

    .campaigns-filter-list {
        padding-top: 1rem;
        .andOr-operator {
            padding: 0 theme.mixin_space(2) 0 0;
            font-size: theme.mixin_toRem(14);
            font-weight: bold;
        }
    }

    .campaigns-filter-inputs {
        display: flex;
        & > .campaigns-filter-input-group {
            flex-grow: 1;

            .filter-value-input-group {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                gap: 1rem;

                .fitler-value-input {
                    flex-grow: 2;
                }

                .add-filter-button {
                    flex-grow: 1;
                }
            }
        }
    }
}
