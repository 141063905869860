.run-data-crons-container {
    .crons-section {
        margin-bottom: 2rem;

        .pmds-dd {
            margin: 0;
        }

        .crons-section-header {
            position: relative;
            text-align: center;
            margin: 3rem 0;

            .reload-crons-button {
                position: absolute;
                top: -10px;
                right: 10px;
            }
        }

        .cron-form-container {
            .form-row {
                display: flex;
                gap: 2rem;
                margin-bottom: 2rem;

                .form-row-item {
                    flex-grow: 1;
                    max-width: 30%;
                    margin: 0;
                    padding: 0;
                }

                .upload-file-input {
                    input {
                        display: block;
                        margin: 0.5rem 0;
                    }
                    a:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
