div.button-container {
    width: 100%;
    padding: 2rem 0;
    text-align: center;

    button.apply-settings-button {
        font-size: 1.25rem;
        font-weight: normal;
        padding: 0.9rem 2.5em;
        outline: none;
    }
}
