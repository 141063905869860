.notes-form {
    .form-inputs-group {
        display: flex;
        margin-top: 1rem;
        .form-inputs-group-item {
            flex-grow: 1;
        }
    }

    .form-buttons {
        margin: 2rem 0;
        display: flex;
        justify-content: center;
    }
}
