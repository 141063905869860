@use '@publicismedia-ds/ui-theme' as theme;

.universal-settings-container {
    padding: 3rem 3rem;
    padding-bottom: 0;
    color: black;

    .universal-settings-title {
        font-weight: normal;
        padding-bottom: 2rem;
        &:hover {
            cursor: pointer;
        }
    }

    label {
        color: black;
    }

    .universal-settings-row {
        padding: 1.5rem 0;
        border-top: 1px solid #d7d7d7;

        &:first-child {
            border-top: none;
        }

        h4 {
            font-size: 1.2rem;
            // font-weight: 700;
        }

        .select-goal-input {
            display: flex;
            align-items: flex-end;
            gap: 2rem;
            .pmds-dd {
                width: 50%;
            }

            .notes-modal-button {
                margin: 0;
            }
        }

        .efficiency-calculator {
            position: relative;

            .efficiency-operator {
                position: absolute;
                top: 35px;
                right: 5px;
                font-size: 2rem;
            }
        }
    }
}
