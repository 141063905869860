.bullet-chart {
    .bullet-chart-header {
        h4 {
            font-weight: bold;
            text-align: center;
            margin-top: 1rem;
        }
    }

    .bullet-chart-content {
        display: flex;
        gap: 1rem;
        text-align: center;
        padding-left: 2rem;
    }
}
