.manage-goal-mappings-container {
    .existing-goals-content {
        .existing-goals-header {
            font-size: 1.8rem;
            font-weight: bold;
            text-align: center;
        }

        .existing-goals-table {
            margin: 3rem 0;

            .wrap-column-text-break-all {
                white-space: normal;
                max-width: 150px;
                word-break: break-all;
            }
            .wrap-column-text-normal {
                white-space: normal;
                max-width: 100px;
                line-height: 1.2rem;
            }

            .action-button {
                visibility: visible;
                border: none;
                background-color: none;
                background: none;
                margin-left: 0.4rem;

                &:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }
            }

            .delete-mapping-modal-content {
                line-height: 1.5rem;
                h4 {
                    font-weight: bold !important;
                }
            }
        }
    }
}
