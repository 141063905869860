.pmds-dt {
    .pmds-dt-th {
        padding: 16px 8px;

        & > * {
            width: 100%;
            height: 100%;

            & > * {
                width: 100%;
                height: 100%;
            }

            .diagnostic-column-header {
                position: relative;
                text-align: center;
                white-space: normal;
            }

            .pmds-txt {
                width: 100%;
            }
        }
    }

    .pmds-dt-td {
        .currency {
            background-color: green;
        }

        .percentage {
            background-color: purple;
        }
    }
}
