@use '@publicismedia-ds/ui-theme' as theme;

.selected-product-container {
    .selected-product-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 70px;
        padding: 1rem 4rem;
        background-color: theme.$color_gray;
        color: theme.$color_white;
        box-sizing: border-box;
    }

    h1 {
        font-size: 2rem;
        font-weight: bold;
        text-transform: capitalize;
        vertical-align: text-bottom;
    }

    .selected-product-content {
        padding: 3rem 3rem;
        h3 {
            text-transform: capitalize;
            font-weight: bold;
        }

        .row {
            margin-top: 3rem;

            &:first-of-type {
                margin-top: 0;
                padding-top: 2rem;
            }
        }
    }

    .no-product-data {
        margin-top: 2rem;
    }
}
