.admin-page-container {
    padding: 2rem 4rem;

    .admin-page-header {
        text-align: center;
        text-transform: capitalize;
        font-weight: bold;
        margin-bottom: 2rem;
    }
}
