@use '@publicismedia-ds/ui-theme' as theme;

nav.pmds-secondary-nav {
    top: 0px !important;
    ul.pmds-secondary-nav__children {
        padding-right: 3rem;
        margin-left: auto;
        li {
            div.pmds-nav-link {
                ul.pmds-nav-link__children {
                    .pmds-nav-link {
                        padding-bottom: 0.2rem;
                    }
                    width: fit-content;
                }
            }
        }
    }
    .pmds-nav-link__text {
        font-size: 0.9rem;
    }

    .pmds-nav-link.active {
        a span {
            position: relative;
        }
        a span::before {
            content: '';
            display: inline-block;
            width: 100%;
            position: absolute;
            bottom: -5px;
            border-bottom: 2px solid white;
        }
    }

    button.nav-alert-button {
        .alert-notification {
            display: block;
            background-color: red;
            color: white;
            font-size: 0.85rem;
            margin-left: 0.5rem;
            padding: 0.1rem 0.45rem 0.2rem 0.45rem;
            border-radius: 100%;
        }
    }
}
