@use '@publicismedia-ds/ui-theme' as theme;

#add-goal-form {
    .add-goal-form-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1rem 0;

        label {
            color: black;
        }

        &.row-items-3 {
            & > * {
                padding: 0;
                margin: 0;
                min-width: 30%;
            }
        }

        &.row-items-4 {
            & > * {
                min-width: 22%;

                input {
                    min-width: 100%;
                }
            }
        }

        &.upload-excluded-inputs {
            justify-content: flex-start;
            gap: 4rem;
        }

        .sync-goal-input {
            label {
                &::before {
                    color: #d1d1d1;
                }
            }
        }

        &.campaigns-selections {
            padding: 1rem 0 2rem 0;

            // border-top: 1px solid #d1d1d1;
            border-bottom: 1px solid #d1d1d1;

            .campaigns-selections-tabs {
                width: 100%;
            }
        }

        .add-goal-form-tool-tip {
            color: theme.$color_brand-1;
            .pmds-tooltip__title {
                font-size: 0.75rem;
                width: 150px;
            }
        }
    }
}
