.view-goal-modal-content {
    max-height: 700px;

    .view-goal-modal-row {
        margin-bottom: 2rem;

        ul {
            li {
                font-size: 1.1rem;
                line-height: 1.6rem;
            }
        }
    }

    h3 {
        margin-bottom: 1rem;
    }
}
