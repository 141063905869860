@use '@publicismedia-ds/ui-theme' as theme;

.text-list-container {
    min-width: 100%;
    .text-list-input {
        display: flex;
        gap: 1rem;
        align-items: flex-end;

        .text-input {
            padding: 0;
            margin: 0 !important;
        }
    }

    .duplicate-error-message {
        margin-top: 0.5rem;
        padding-left: 1rem;
        font-size: 0.8rem;
        color: red;
    }

    .icon-add {
        font-size: 2.3rem;
        cursor: pointer;
    }

    .text-list-content {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin-top: 1rem;
    }
}
