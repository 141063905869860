.edit-goal-modal-container {
    // width: 85%;

    .edit-goal-form {
        label {
            color: black;
        }

        .edit-goal-header {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            h1.modal-heading {
                margin: 0 !important;
            }

            .edit-goal-name-input {
                margin: 0;
                margin-left: 0.5rem;
                width: 600px;
                max-width: none;

                input {
                    max-width: 100%;
                    font-size: 1.1rem;
                    text-overflow: ellipsis;
                }
            }

            .icon-edit {
                position: absolute;
                top: 10px;
                right: 150px;
                font-size: 1.5rem;
            }

            &:hover {
                cursor: pointer;
            }
        }

        .edit-goal-content {
            overflow: hidden;
            // margin-bottom: 0.25rem;
            .edit-goal-row {
                display: flex;
                margin-bottom: 2rem;
                p {
                    margin: 0.5rem 0;
                    font-size: 1rem !important;
                }

                &.campaign-selection-inputs {
                    padding-bottom: 2rem;
                    border-top: 1px solid #bbb;
                    border-bottom: 1px solid #bbb;
                }

                &.goal-data-read-only {
                    display: block;
                }

                &.upload-excluded-inputs {
                    justify-content: flex-start;
                    gap: 4rem;
                }
            }
        }

        .goal-property-name {
            font-weight: bold;
        }
    }
}
