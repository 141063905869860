.bid-headroom-content {
    .bid-headroom-form {
        width: 100%;

        .bid-headroom-dropdown {
            min-width: 30%;
        }

        .submit-button-container {
            width: 100%;
            text-align: center;
        }
    }
}

.bid-headroom-table {
    max-width: 100%;

    .pmds-dt {
        .pmds-dt-table {
            max-height: none;
            position: relative;

            .caption-container {
                position: relative;
                height: 20px;
                width: 100%;

                .caption-content {
                    position: absolute;
                    display: block;
                    top: 0;
                    left: 6.1%;
                    width: 93.9%;
                    height: 100%;

                    .caption {
                        position: relative;
                        display: inline-block;
                        font-size: 1.1rem;
                        height: 100%;
                        text-align: center;
                        vertical-align: middle;
                        font-weight: normal;
                        width: 43.35%;

                        &::before {
                            content: '';
                            position: absolute;
                            top: -8px;
                            left: 0;
                            display: block;
                            width: 1px;
                            height: 38px;
                            background-color: #d1d1d1;
                        }

                        &.last-month {
                            &::after {
                                content: '';
                                position: absolute;
                                top: -8px;
                                right: 0;
                                display: block;
                                width: 1px;
                                height: 38px;
                                background-color: #d1d1d1;
                            }
                        }
                    }
                }
            }

            .content-table-header {
                display: block;
                width: 100%;
                text-align: center;
            }
        }
    }

    .bid-headroom-table-header {
        text-align: center;
    }

    .download-scan-button-container {
        display: flex;
        justify-content: flex-end;
        margin: 2rem 0;
    }
}
