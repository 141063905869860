.schedule-goal-form-container {
    width: 100%;

    .schedule-goal-form-header {
        margin: 2rem 0;
        h3 {
            text-align: center;
            text-transform: capitalize;
        }
    }

    .schedule-goal-form-content {
        form {
            width: 100%;

            .form-row {
                padding-bottom: 1.5rem;
                display: flex;
                align-items: baseline;
                gap: 1rem;

                &.row-group {
                    & > * {
                        padding: 0;
                        margin: 0;
                        flex-grow: 1;
                        max-width: 33% !important;
                    }
                }

                &.form-emails-input {
                    width: 100%;
                }

                &.form-submit-button {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}
