.products-container {
    .pmds-tabs {
        .pmds-tabs__tabs {
            position: fixed;
            width: 100%;
            z-index: 100;
            background-color: white;
            .pmds-tab {
                text-transform: capitalize;
            }
        }
    }
}
