.add-scheduled-goal-container {
    width: 100%;

    .add-scheduled-goal-header {
        margin-bottom: 2rem;
        h3 {
            text-align: center;
            text-transform: capitalize;
        }
    }
}
