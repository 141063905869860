.manage-user-form-container {
    .form-row {
        margin-top: 2rem;
        .form-inputs {
            margin-top: 1rem;
            padding: 0;
            display: flex;
            gap: 1rem;

            & > * {
                padding: 0 !important;
                margin: 0 !important;
                flex-grow: 1;
            }
        }
        .error-message {
            margin-top: 0.5rem;
            color: red;
        }
    }
    .form-submit-button {
        display: flex;
        justify-content: center;
    }
}
