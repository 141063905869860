.change-summary-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;

    .change-summary-goal-dropdown {
        min-width: 60%;
    }

    .change-summary-datepicker {
        min-width: 30%;

        .react-datepicker-wrapper {
            width: 100%;

            .pmds-txt {
                width: 100%;
            }
        }
    }

    .change-summary-times-fieldset {
        label {
            div {
                color: black !important;
            }
        }
    }
}

.submit-button-container {
    margin: 3rem 0;
    text-align: center;
}

.change-logs-settings {
    .change-logs-settings-header {
        max-width: 80%;
        margin: 0 auto;

        h2 {
            margin-bottom: 1rem;
        }

        .change-logs-table-header {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin-bottom: 1rem;

            h3 {
                font-weight: bold;
            }
        }
    }

    .change-logs-settings-table {
        margin: 2rem auto;
        min-width: 80%;
        td {
            box-shadow: 0 0 1px #111;
            padding: 1rem;
            background-color: #f5f9ff;

            span {
                display: inline-block;
                margin-right: 0.5rem;
                font-weight: bold;
            }
        }
    }
}

.change-logs-table-content {
    margin-bottom: 3rem;

    h2 {
        margin-bottom: 2rem;
    }
}
