.notes-modal-container {
    width: 80vw;

    .notes-modal-header {
        text-align: center;

        .icon-close {
            float: right;
            font-weight: bold;
            transform: translateX(-2rem);

            &:hover {
                cursor: pointer;
            }
        }
    }

    div.notes-modal-content {
        max-height: 60vh;
        overflow: scroll;

        p.notes-info {
            font-size: 1.1rem !important;
            margin: 0.75rem 0 !important;
        }
    }
}
