.custom-alerts-container {
    margin: 2rem 2rem 2rem 3rem;
    .custom-alerts-header {
        text-align: center;
        margin-bottom: 1rem;
    }

    .custom-alerts-content {
        .custom-alerts-table-container {
            margin-right: 1rem;
        }
    }
}

.custom-alert-modal-container {
    .modal-frame {
        width: 100%;

        .modal-heading {
            margin-bottom: 0 !important;
        }

        .pmds-dd__menu-list {
            max-height: 200px;
        }

        .pmds-txt__invert input:disabled,
        .pmds-txt__invert textarea:disabled {
            color: #393939 !important;
        }
    }
}
