.pacing-doc-form-container {
    padding-top: 2rem;

    .pacing-doc-form-content {
        .formula-columns-checkbox {
            margin: 0 1rem 0 0;
            display: flex;
            justify-content: flex-end;
        }

        .formula-columns-container {
            margin-top: 2rem;
            .formula-columns-header {
                background-color: #e9e9e9;
                padding: 0.5rem;
                margin-right: 1.5rem;
                font-weight: bold;
                font-size: 0.95rem;
                text-transform: capitalize;
            }

            .formula-column-form {
                padding-right: 2rem;
                grid-template-columns: repeat(4, 1fr) 0.2fr !important;

                .column-two-dd {
                    width: 80%;
                }

                .add-formula-column {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translate(50%, -45%);

                    i {
                        font-size: 1.7rem;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .formula-columns-table {
                margin: 2rem 0;
                margin-bottom: 0;
                width: 44%;
                border: 1px solid #e9e9e9;

                thead {
                    tr {
                        text-align: left;
                        background-color: #e9e9e9;
                        font-weight: bold;

                        th {
                            padding: 0.5rem;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            padding: 0.5rem;
                            .pmds-chk {
                                margin: 0;
                            }

                            &.column-formula {
                                display: flex;
                                justify-content: space-between;
                                padding: 0.5rem 1rem 0.5rem 0.5rem;

                                i {
                                    &:hover {
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
