$classNameComponent: 'match-type-analysis';
$classNamePrefix: $classNameComponent + '-';

.#{$classNamePrefix}container {
    padding-top: 2rem;
    margin-bottom: -1rem;
    .#{$classNamePrefix}header {
        text-align: center;
    }

    .universal-settings-container {
        padding-top: 0;
    }
}
