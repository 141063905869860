.edit-notes-form-container {
    .note-info {
        font-size: 1.05rem;
        margin-bottom: 1rem;
    }

    h1 {
        text-align: center;
        i {
            float: right;
            padding: 0.25rem;
            &:hover {
                cursor: pointer;
            }
        }
    }

    .form-buttons {
        margin: 2rem;
        display: flex;
        justify-content: center;
        gap: 2rem;
    }
}
