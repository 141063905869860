@use '@publicismedia-ds/ui-theme' as theme;

.advertiser-notes-table {
    .note-text-column {
        width: 300px;

        .wrap-column-text-normal {
            width: 100%;
            font-size: inherit;
            white-space: normal;
            margin: 0;
            padding: 0;

            &.text-strike-through {
                text-decoration: line-through;
            }
        }
    }

    .timestamp-data {
        text-align: center;

        p:first-child {
            margin-bottom: 0.3rem;
        }
    }

    .notes-checkbox {
        display: flex;
        min-width: 100%;
        justify-content: center;
    }

    .icon-edit {
        padding: 0.2rem;
        padding-top: 0;
        color: theme.$color_brand-1-lighter;
        visibility: hidden;

        &:hover {
            cursor: pointer;
        }
    }

    .icon-cancel-20 {
        padding: 0.2rem;
        color: theme.$color_red;

        &:hover {
            cursor: pointer;
        }
    }

    .pmds-dt-tr {
        &:hover {
            .icon-edit {
                visibility: visible;
            }
        }

        .pmds-accordion-control__btn:has(.no-history) {
            background-color: yellow;
        }
    }

    .no-note-history {
        .pmds-accordion-control {
            visibility: hidden;
        }
    }
}
