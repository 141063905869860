div.input-error-container {
    margin: 0 !important;

    .input-error-text {
        display: block;
        height: 100% !important;
        margin: 0;
        font-size: 0.8rem;
        color: red;
    }
}
