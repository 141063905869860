.account-onboarding-form-container {
    margin: 2rem 0;
    padding: 0 1rem;

    .title {
        margin-bottom: 2rem;
    }

    .submit-button-container {
        display: flex;
        justify-content: center;
        gap: 2rem;
        button {
            font-size: 1.2rem;
            padding-left: 4rem;
            padding-right: 4rem;
        }
    }

    .edit-account-header {
        background-color: #dedede;
        padding: 0.5rem;
        font-weight: bold;
        margin-top: 3rem;
        text-transform: capitalize;
    }

    .inputs-row {
        position: relative;
        margin: 1rem 0;
        display: flex;
        align-items: center;
        gap: 2rem;

        & > * {
            margin: 0;
            padding: 0;
            flex-grow: 1;
        }

        .support-options {
            padding-left: 1rem;
            padding-bottom: 1rem;
        }

        .pmds-txt {
            margin: 0 !important;
        }

        .text-list-container {
            min-width: 45% !important;

            .text-list-input {
                .pmds-txt {
                    width: 100%;
                }
            }
        }

        .pmds-fs {
            margin: 0;
        }

        .icon-close {
            position: absolute;
            right: -30px;
            top: 35px;
            font-size: 1.2rem;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .insert-inputs-row-button {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
    }
}
