.bulk-actions-container {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    .more-menu-item {
        padding: 0 1rem;
    }
}

.bulksheet-logs-table {
    width: 70vw;
}

.upload-modified-file {
    min-width: 40vw;
    text-align: center;
    input {
        font-size: 1.2rem;
    }
}
