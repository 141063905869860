.loading-modal-content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;

    .loading-modal-header {
        font-size: 2rem;
        text-transform: capitalize;
        color: rgba(245, 245, 245, 0.8);
        letter-spacing: 0.2rem;
        z-index: 1000000;
    }
}
