.adhoc-analysis-container {
    width: 60vw;
    min-height: 120px;

    .pmds-col {
        label {
            color: black;
        }

        .pmds-txt {
            input {
                max-width: 92%;

                // background-color: yellow;
            }
        }

        & > * {
            margin-top: 1rem;
            align-self: flex-end;
        }

        .react-datepicker-wrapper {
            width: 100%;
            margin: 0;

            .pmds-txt {
                width: 100%;
                max-width: none;

                .pmds-txt__input-wrap {
                    width: 100%;
                }
            }
        }

        .pmds-chk {
            margin-top: 2rem;
            align-self: center;
        }

        .pmds-fs {
            margin-top: 1.5rem;
            .pmds-rdo__invert {
                &.pmds-rdo__as-button {
                    .pmds-rdo__children {
                        color: black;
                    }
                }
            }
        }
    }
}
