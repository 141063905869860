.yield-optimizer-settings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    h3 {
        margin-bottom: 1rem;
    }

    label {
        padding-left: 1rem;
        font-size: 1rem;
    }
}

// SETTINGS TOGGLE
.radio-toggle {
    align-self: center;

    &.optimize-budget {
        min-width: fit-content;
    }
}

// SETTINGS BULKSHEET FILE OPTION
.bulksheet-file-options {
    min-width: fit-content;
    fieldset {
        margin: 0;
    }
}

.yield-optimizer-content {
    h3 {
        margin-bottom: 2rem;
    }
    .yield-optimizer-expected-chart {
        display: flex;
        text-transform: capitalize;
        border-top: 1px solid #d9e4f5;
        border-bottom: 1px solid #d9e4f5;

        .expected-chart-item {
            padding: 2rem 0;
            flex-grow: 1;
            text-align: center;

            .expected-chart-item-value {
                font-size: 2rem;
                font-weight: bold;
                margin-bottom: 1rem;
            }

            .expected-chart-item-label {
                font-size: 1.5rem;
                color: #828fa3;

                span {
                    color: black;
                    font-weight: bold;
                }
            }

            &.expected-chart-item-middle {
                border-left: 1px solid #d9e4f5;
                border-right: 1px solid #d9e4f5;
            }
        }
    }

    .optimization-insights-list {
        padding-left: 2rem;
        list-style: disc;

        li {
            margin-bottom: 1rem;
        }
    }

    .bulksheet-logs-table {
        min-width: 80% !important;
    }
}
