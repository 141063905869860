.upload-excluded-container {
    min-width: 30%;

    .file-input-group {
        label {
            display: block;
            margin: 1rem 0;
        }

        .file-input-dropdown {
            label {
                margin: 0;
                color: #bbb;
            }
            .pmds-dd {
                margin-top: 0.5rem;
                width: 100%;
            }
        }
    }
}
