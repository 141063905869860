@use '@publicismedia-ds/ui-theme' as theme;

$classNameComponent: 'pmds-dd';
$classNamePrefix: $classNameComponent + '__';

li.pmds-pill {
    display: inline-block;
    margin: 0 theme.mixin_space(2) theme.mixin_space(2) 0;

    button {
        color: theme.$color_slt-multi-txt;
        background-color: transparent;
        border: 2px solid theme.$color_slt-multi-border;
        border-radius: theme.mixin_space(4);
        padding: theme.mixin_space(1) theme.mixin_space(3);
        font-size: theme.mixin_toRem(14);
        cursor: pointer;

        &:hover {
            background-color: theme.$color_slt-multi-bg-hover;
        }
        &:disabled {
            color: theme.$color_slt-multi-txt-disabled;
            background-color: transparent;
            border-color: theme.$color_slt-multi-clear-border-disabled;
            cursor: not-allowed;

            abbr:last-child {
                cursor: not-allowed;
            }
        }

        abbr:last-child {
            margin-left: theme.mixin_space(2);
            font-size: 0;
            text-decoration: none;

            &:before {
                content: theme.$icon_close;
                font-family: theme.$icon_font;
                font-size: theme.mixin_toRem(8);
                font-weight: bold;
                display: inline;
                position: relative;
                top: -1px;
            }
        }
    }

    .#{$classNamePrefix}pillInvert {
        border-width: 2px;
        &:disabled {
            color: theme.$color_slt-multi-txt-disabled-invert;
            border: 2px solid theme.$color_slt-multi-border-disabled-invert;
        }
    }

    &.#{$classNamePrefix}multi-values-clear {
        button {
            color: theme.$color_slt-multi-clear-txt;
            background-color: transparent;
            border: 2px solid theme.$color_slt-multi-clear-txt;

            &:hover {
                background-color: theme.$color_slt-multi-clear-bg-hover;
            }

            &:disabled {
                color: theme.$color_slt-multi-clear-txt-disabled-invert;
                border-color: theme.$color_slt-multi-clear-border-disabled;
            }
        }
        &.#{$classNamePrefix}clearInvert {
            button {
                color: theme.$color_slt-multi-clear-txt-invert;
                border: 2px solid theme.$color_slt-multi-clear-txt-invert;
                background-color: transparent;

                &:hover {
                    background-color: theme.$color_slt-multi-clear-bg-hover-invert;
                }

                &:disabled {
                    color: theme.$color_slt-multi-clear-txt-disabled-invert;
                    border-color: theme.$color_slt-multi-border-disabled-invert;
                }
            }
        }
    }
}
