@use 'reset-css';
@use '@publicismedia-ds/ui-theme' as theme;

//Only needed once
@include theme.font_importRoboto; //Import Roboto font from Google
@include theme.font_typography; //Base styles for various tags
@include theme.icon_importIcons; //Import icon font from hosted url
@include theme.color_paletteClasses; //Mixin adds color classes for color and background-color

html,
body {
    min-height: 100vh;
    max-width: 100vw;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    #root {
        color: #4e463b;

        span.pmds__error {
            color: theme.$color-red;
            font-weight: bold;
        }

        .pmds-btn {
            margin: 0;
        }

        .action-button {
            background: none;
            outline: none;
            border: none;

            &:hover {
                cursor: pointer;
            }
        }

        // DATA TABLE
        .pmds-dt {
            // TABLE HEAD
            .pmds-dt-th {
                text-transform: capitalize;
                // ALIGN TEXT CENTER
                &.pmds-dt-th__align-center {
                    .pmds-dt-th__sort-wrap {
                        display: flex;
                        justify-content: center;

                        div {
                            width: fit-content;
                        }

                        button {
                            width: fit-content;
                        }
                    }
                }
                // ALIGN TEXT LEFT
                &.pmds-dt-th__align-left {
                    div {
                        text-align: left;
                    }
                }
                // ALIGN TEXT RIGHT
                &.pmds-dt-th__align-right {
                    div {
                        text-align: right;
                    }
                }

                // MOVE SORT CARROT TO RIGHT SIDE
                &[data-type='currency'] {
                    .pmds-dt-th__sort-wrap {
                        flex-direction: row;
                    }
                }
            }

            .data-anomaly-flag {
                &:has(div.anomaly-flag) {
                    background-color: #f6416280;
                }
            }
        }

        th.align-head-center {
            div {
                position: relative;
                text-align: center;
                width: 100%;

                .pmds-dt-th__sort-btn {
                    position: absolute;
                    left: 0;
                }
            }

            &.pmds-dt-th__min-width {
                .pmds-dt-th__children {
                    padding: 0 2rem;
                }
            }
        }

        .pmds-dt-td {
            &.status-column .pmds-dt-td__children {
                position: relative !important;
                overflow: visible !important;
            }

            &.currency {
                .pmds-dt-td__children::before {
                    content: '$';
                }
            }
            &[data-type='percent'] {
                .pmds-dt-td__children {
                    &::after {
                        content: '%';
                    }
                }
            }
        }

        .pmds-col {
            margin-top: 0.2rem;
            & > * {
                align-self: flex-start !important;
            }
            .pmds-txt {
                max-width: none;

                input {
                    width: 100%;
                    max-width: none;
                    // background-color: yellow;
                }
            }

            & > * {
                margin-top: 1rem;
                align-self: flex-end;
            }

            .react-datepicker-wrapper {
                width: 100%;
                margin: 0;

                .pmds-txt {
                    width: 100%;
                    max-width: none;

                    .pmds-txt__input-wrap {
                        width: 100%;
                    }
                }
            }

            .pmds-chk {
                margin-top: 2rem;
                align-self: center;
            }

            .pmds-fs {
                margin-top: 1.5rem;
                .pmds-rdo__invert {
                    &.pmds-rdo__as-button {
                        .pmds-rdo__children {
                            color: black;
                        }
                    }
                }
            }

            label {
                color: black;
            }
        }

        .pmds-btn__red {
            & > * {
                color: white;
            }
        }

        .react-datepicker__day--disabled {
            color: #c3c3c3 !important;
        }

        .form-submit-button {
            margin: 2rem;
            text-align: center;
        }

        .position-relative {
            position: relative;
        }
    }
}
