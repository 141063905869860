@use '@publicismedia-ds/ui-theme' as theme;

.data-upload-container {
    margin-top: 3rem;
    padding: 2rem 3rem;
    padding-bottom: 0;

    h3 {
        text-transform: capitalize;
    }

    .data-upload-row {
        margin: 2rem 0;

        .data-upload-notes-header {
            margin: 0.5rem 0;
            font-size: 1rem;
            font-weight: bold;
        }

        .data-upload-notes-list {
            padding-left: 1.5rem;
            list-style-type: decimal;
            font-size: 0.9rem;

            li {
                margin: 0.5rem;
            }
        }

        .download-template-btns {
            display: flex;
            justify-content: space-evenly;
            gap: 1rem;
            flex-wrap: wrap;

            button {
                font-size: 0.8rem;
                text-transform: capitalize;
            }
        }

        &.upload-submit {
            text-align: center;
            margin: 0;
            padding-top: 1rem;
            padding-bottom: 2rem;
        }
    }

    .data-upload-form {
        .data-upload-form-inputs {
            .upload-input {
                text-align: center;

                label {
                    font-size: 1.2rem;
                }

                input {
                    display: inline-block;
                    margin-top: 1rem;
                    transform: translateX(40px);
                }
            }
        }
    }

    .data-upload-product-content {
        padding: 2rem 3rem;
    }
}
