.darwin-alerts-modal-content {
    min-width: 80vw;
    .padding-bottom {
        div {
            padding-bottom: 0.125rem;
        }
    }
    .pmds-dt-td.table-icon {
        icon {
            color: red;
        }

        div {
            width: 100%;
            div {
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }
}
