.scenario-planner-calculator {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;

    .scenario-planner-calculator-field {
        flex-grow: 1;
        text-align: center;
        padding: 2rem;
        border: 1px solid #00000044;

        .calculator-field-input {
            position: relative;
            &.is-dollars::before {
                content: '$';
                font-size: 2rem;
                position: absolute;
                top: 50px;
                left: 0px;
            }
            label {
                text-transform: capitalize;
                color: black;
            }

            .pmds-txt {
                width: 80%;
                &.isDollars::before {
                    content: 'hello';
                    color: black;
                }

                input {
                    padding: 0;
                    text-align: center;
                    min-width: 100%;
                    font-size: 2rem;
                    border: none;
                    border-bottom: 1px solid #00000044;
                }
            }
        }

        .calculator-field-button {
            margin-top: 1.5rem;

            button {
                font-size: 1rem;
            }
        }
    }
}
