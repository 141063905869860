.bullet-charts-container {
    margin-bottom: 2rem;
    .bullet-charts-header {
        h2 {
            font-size: 1.3rem;
            text-transform: capitalize;
        }
    }
    .bullet-charts-content {
        display: flex;
        justify-content: space-between;
    }
}
