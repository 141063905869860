.select-campaigns-container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;

    & > * {
        flex-grow: 1;
    }
    .file-input-group {
        label {
            display: block;
            margin: 1rem 0;
        }
    }

    & > * {
        min-width: 30%;

        label {
            &::before {
                color: #d1d1d1;
            }
        }
    }
}
