@use '@publicismedia-ds/ui-theme' as theme;

.login-container {
    margin-top: 6%;

    .error-message {
        width: 60%;
        padding: 0.7rem;
        margin: 2rem auto;
        background-color: theme.$color_red-1;
        color: whitesmoke;
        border-radius: 5rem;
        text-align: center;

        p {
            font-size: 1.1rem;
            margin: 0;
        }
    }

    .login-content {
        width: 35%;
        text-align: center;
        margin: auto;

        h4 {
            margin: 1rem 0;
        }

        .pmds-txt {
            width: 100%;
            text-align: left;
        }

        .login-btn-container {
            margin-top: 1.5rem;

            .login-btn {
                width: 100%;
                padding: 0.8rem;
                background-color: #435369;
                border-color: #435369;
                color: white;

                &:hover {
                    background-color: #435369ee;
                    border-color: #435369ee;
                    color: white;
                }
            }
        }

        .login-footer {
            margin-top: 1rem;
            .forgot-password-btn {
                font-size: 0.8rem;
                color: black;

                &:hover {
                    color: #00000099;
                }
            }

            p {
                display: flex;
                justify-content: center;
                margin-top: 3rem;
                color: gray;
                font-size: 0.8rem;
                .logo-performics {
                    width: 5rem;
                    align-self: baseline;
                }
            }
        }
    }
}
