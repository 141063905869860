.anomaly-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
        text-transform: capitalize;
    }
}

.day-over-day-chart {
    margin-top: 2rem;

    .pmds-dt {
        .pmds-dt-thead {
            .pmds-dt-tr {
                .pmds-dt-th {
                    text-align: center;
                }
            }
        }
    }
}
.text-align-center {
    text-align: center !important;
}

.justify-column-center {
    width: 100%;
    display: flex;
    justify-content: center;
}

.center-column-text {
    width: 100%;
    text-align: center;
}

.performance-graph {
    width: fit-content;
    margin-top: 1rem;
    h3 {
        text-align: center;
    }
}

.keyword-performance-graphs {
    display: flex;
    .keyword-performance-graph {
        flex-grow: 1;
        text-align: center;
    }
}

.keyword-chart {
    margin-top: 3rem;

    h3 {
        margin-bottom: 3rem;
    }
    .pmds-dt .pmds-dt-table {
        max-height: none;
    }
}

.anomalies-data-table-above {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 1rem;
    margin: 0;
}
